import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import $store from '@/store/index';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/Login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/pcshow',
    name: 'pcshow',
    meta: { title: 'PC模式' },
    component: () => import('@/views/PcShow.vue')
  },
  {
    path: '/CheckFile2',
    name: 'CheckFile2',
    meta: { title: '资料详情',isAuth:true },
    component: () => import('@/views/Resource/CheckFile2.vue'),
  },
  {
    path: '/',
    name: 'Index',
    meta: { title: '大首页',isAuth:false,tabbar:"module" },
    component: () => import('@/views/Index.vue'),
    redirect:{path:"/Module"},
    children:[{
      path: '/Module',
      name: 'Module',
      meta: { title: '父页面',isAuth:true },
      component: () => import('@/views/Module.vue'),
      redirect: { path: '/Home' },
      children: [{
        path: '/Home',
        name: 'Home',
        meta: { title: '首页',isAuth:true },
        component: () => import('@/views/Home/Home.vue'),
      },{
        path: '/Question',
        name: 'Question',
        meta: { title: '选择科目',isAuth:true },
        component: () => import('@/views/QuestionBank/SelectAccount.vue'),
      },{
        path: '/Course',
        name: 'Course',
        meta: { title: '推荐课程',isAuth:true },
        component: () => import('@/views/QuestionBank/SelectAccount.vue'),
      },{
        path: '/Resource',
        name: 'Resource',
        meta: { title: '资料',isAuth:true },
        component: () => import('@/views/Resource/Resource.vue'),
      },{
        path: '/Mine',
        name: 'Mine',
        meta: { title: '我的',isAuth:true },
        component: () => import('@/views/Mine/Mine.vue'),
      }]
    },
    {
      path: '/QuestionPage',
      name: 'QuestionPage',
      meta: { title: '' },
      component: () => import('@/views/QuestionBank/QuestionPage.vue')
    },
    {
      path: '/QuizPage',
      name: 'QuizPage',
      meta: { title: '' },
      component: () => import('@/views/QuestionBank/QuizPage.vue')
    },
    {
      path: '/QuizWork',
      name: 'QuizWork',
      meta: { title: '' },
      component: () => import('@/views/QuestionBank/QuizWork.vue')
    },
    {
      path: '/ExamWork',
      name: 'ExamWork',
      meta: { title: '' },
      component: () => import('@/views/QuestionBank/ExamWork.vue')
    },
    {
      path: '/ExamResult',
      name: 'ExamResult',
      meta: { title: '模拟考试成绩' },
      component: () => import('@/views/QuestionBank/ExamResult.vue')
    },
    {
      path: '/ExamHistory',
      name: 'ExamHistory',
      meta: { title: '历史记录' },
      component: () => import('@/views/QuestionBank/ExamHistory.vue')
    },
    {
      path: '/ExamRemember',
      name: 'ExamRemember',
      meta: { title: '历史记录' },
      component: () => import('@/views/QuestionBank/ExamRemember.vue')
    },
    {
      path: '/CourseList',
      name: 'CourseList',
      meta: { title: '推荐课程',isAuth:true },
      component: () => import('@/views/Course/RecommendedCourses.vue'),
    },
    {
      path: '/CourseDetail',
      name: 'CourseDetail',
      meta: { title: '课程详情',isAuth:true },
      component: () => import('@/views/Course/CourseDetail.vue'),
    },
    {
      path: '/CheckFile',
      name: 'CheckFile',
      meta: { title: '资料详情',isAuth:true },
      component: () => import('@/views/Resource/CheckFile.vue'),
    },
    {
      path: '/EditPassword',
      name: 'EditPassword',
      meta: { title: '密码修改',isAuth:true },
      component: () => import('@/views/Mine/EditPassword.vue'),
    },
    ]
  },

  


  /**
   * 路由重定向
   */
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to:any, from:any, next:any) => {
  $store.commit('personData/changeTitle',to.meta.title)
  $store.commit('personData/changeClass',to.path);
  const token = localStorage.getItem('token')  //读取token
  if (to.meta.isAuth) { //判断是否需要鉴权
    if (token === null) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
