export default {
    namespaced:true,
    state: {
        title:"物流考试系统",
        path:"/gateway",
        tabbar:"module",
        Id:'',//业务Id
    },
    //用于操作数据的同步方法
    mutations: {
        changeTitle(state: any, data: any) {
            state.title = data
        },
        changeClass(state: any, data: any){
            state.path = data
        },
        changeOperatorId (state:any,value:any){
            state.OperatorId = value
        },
        changeTabbar(state:any,value:any){
            state.tabbar = value
        },
        changeId(state: any, data: any) {
            state.Id = data
        },
    },
}